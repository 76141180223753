import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import H1 from "../../components/h1"
import ContentPage from "../../layouts/contentPage"
import ContentPageWrapper from "../../layouts/contentPageWrapper"
import styled from "styled-components";

const PolicyWrapper = styled.div`
    font-size: 14px;
    line-height: 20px;
    h1{
      font-size: 40px;
      text-align:center;
      margin-bottom: 100px;
    }
    p,li{
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 1rem;
        padding-left: 10px;
        margin-left: -10px;
    }
    ul,ol{
      margin: 0;
      padding: 0;
    }
    li>ul,
    li>ol{
      margin-top: 10px;
      padding-left:24px;
      &[type='disc']{
        padding-left: 0;
        margin-left: 23px;
      }
    }
    h2{
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 40px;
      margin-top: 40px;
    }
    ul[type="disc"]{
      margin-left: -4px;
      padding-left: 0;
      &>li{
        margin-left: -2px;
        padding-left: 5px;
      }
    }
    &>ol{
      padding: 0 15px;
    }
    &>ol>li{
      padding-left: 10px;
      margin-left: 0px;
    }
    #cookies{
      scroll-margin-top : 200px;
    }
`

const PrivacyPolicy = () => (
  <Layout lang={'EN'}>
    <Seo title="Privacy policy" lang={'EN'} />
    <ContentPage>
      <ContentPageWrapper variant={'centered'}>
        <PolicyWrapper>
          <H1 className="h1--contact">PRIVACY POLICY</H1>
          <h2>Privacy & Cookie Policy</h2>
          <p>Your privacy is important to us and we wish to be transparent about how we process data of our Users. That is why we prepared this notice to help you understand how we aim to protect, safeguard and collect your personal information. This notice also describes the choices you can make about the way your information is collected through the necessary cookies. The notice applies to any cookies collected by us through the use of this Website and any other interactions with us.</p>
          <p>Aflofarm Farmacja Polska Sp. z o.o., organised and existing under the laws of Poland, seated at Partyzancka 133/151, 95-200 Pabianice, entered into the Register of Entrepreneurs of the National Court Register by the District Court for Łódź-Śródmieście XX Commercial Division with the KRS number 0000014115, share capital PLN 26,271,500.00, NIP: 731-18-21-205, REGON: 472871255, hereinafter also referred to as the “Aflofarm” is the Data Controller of Your personal data. </p>
          <p>If you wish to make a complaint about the way in which we handle your personal information, please contact our Privacy Officer via email: daneosobowe@aflofarm.pl We undertake to acknowledge and investigate any complaint promptly and will respond to written complaints within a reasonable timeline from the date of receipt. </p>
          <ol>
              <li>Definitions:
                  <ul type={'disc'}>
                      <li>Website or Site - web page with the address URL: <a data-fr-linked="true" href="https://oxygenetic.pl/en ">https://oxygenetic.pl/en</a>;</li>
                      <li>Third Party Service  - web page of our partners, service providers or customers;</li>
                      <li>User - a natural or legal person, a visitor to the Website to whom we provide electronic services or reply to enquiries via our contact form available on the Website; </li>
                      <li>Data Controller  - Aflofarm Farmacja Polska Sp. z o.o., organised and existing under the laws of Poland with its registered seat at Partyzancka 133/151, 95-200 Pabianice, entered into the Register of Entrepreneurs of the National Court Register by the District Court for Łódź-Śródmieście XX Commercial Division with the KRS number 0000014115, share capital PLN 26,271,500.00, NIP: 731-18-21-205, REGON: 472871255, hereinafter also referred to as “Aflofarm”;</li>
                      <li>User's Terminal Device - User's end device and software through which the User gets access to the Website. </li>
                  </ul>
              </li>
              <li>This privacy notice describes our collection, use and processing practices at Aflofarm with respect to information, including personal data, obtained or collected through the Website by  Aflofarm Farmacja Polska Sp. z o.o. who is the Data Controller. </li>
              <li>Cookie Policy outlines rules for placing, storing and accessing data on User’s end device through which Website of Data Controller is accessed electronically. This policy also provides information on the lifetime of cookies, i.e. how long the cookie will remain on your device and whether these files can be accessed by any third parties. </li>
              <li>Your personal data shall be processed by Aflofarm for compliance with legal obligations to which the Data Controller is subject under Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (the General Data Protection Regulation or 'GDPR'). </li>
              <li>When you visit our Website, we may collect information on your browsing activity or as a visitor to the Site that can be used to identify you as an individual (PII or personally identifiable information). These data may include:
              <ol type="a">
                  <li>information on how you use our Website, including your IP address, pages viewed, time spent on site and other statistics relating to your browsing behaviour; </li>
                  <li>technical information of devices used by you as a visitor to access our Site and other technical data; </li>
                  <li>session recording of mouse clicks, movement, scrolling, your activity on the Website or other web pages or items viewed;</li>
                  <li>any other data that a visitor to the Site provides electronically via our contact form, if any, or email. </li>
              </ol>
              </li>
              <li>The types of personal information that we collect from you will depend on how you use the Website can be processed by Aflofarm as Data Controller, any such processing limited to: 
                  <ol type="a">
                      <li>replying to your enquiry/submission regarding products of Aflofarm, dealings with or operations of Aflofarm - subject to the prior consent of the User to any such processing or where the processing of personal data is determined in the legal basis for compliance with the applicable regulations then at all times limited to the Data Controller’s vested or legitimate interest – pursuant to Article 6 (1) (a) and (f) of GDPR; </li>
                      <li>processing and handling of report on undesirable effect or adverse reaction under the Article 6 (1) (c) of GDPR Aflofarm is subject to as the Data Controller; </li>
                      <li>processing and handling of complaints under the vested interest of Data Collector pursuant to Article 6 (1) (f) of GDPR; </li>
                      <li>delivering more targeted, enhanced or personalised content on product updates wherever you have consented us to provide any marketing content or other updated information about our product offering, products or services (article 6 (1) (a) of the GDPR and Article 172 of the Telecommunications Law and Article 10 (2) of the Act on Provided Services by Electronic Means). </li>
                      <li>processing of personal data shall be carried out under Article 6 (1) (b) of the GDPR if so required for the purpose of executing or performing an agreement or contract to which User is the party or to take actions or measures, at the User’s request, or as reasonably required prior to the execution thereof. </li>
                      <li>compiling aggregate data about the Site traffic, User’s behaviour and any analytics relating to remembering the preferences of any returning visitors to the Site under the legitimate and vested interest pursued by the Data Controller  under Article 6 (1) (a) and (f) of the GDPR consisting in operating the Website, ensuring Site functionality and reliability;</li>
                      <li>compiling statistics and analytics of behaviour and tracking Website performance to improve how our Website works, enable Users to move around the Website and use its features and increase sales volumes – Article 6 (1) (a) of the GDPR. </li>
                  </ol>
                  <p>You are under no obligation to provide us with your personal information yet it is essential for the processing of your enquiry/ADR submission on products, dealings with us or operations of Aflofarm, processing of reports of undesirable effect or adverse reaction or handling your complaints so that if you choose to withhold your personal information or revoke your consent we may not be able to provide you with the services or products that depend on the collection and use of that information. Wherever the purpose is execution of contract or agreement, providing personal data is required by law for the execution and performance thereof.</p>
              </li>
              <li>Personal information may be subject to the processing and storage by Aflofarm on grounds of legitimate or vested interest of Aflofarm wherever the legitimate interest of the Data Controller supersedes the legitimate interest or rights of the User. Whenever the legitimate interests of the Data Controller is superior to the vested interest and rights of the User, personal data can be collected, stored and processed to the extent required by law unless a data subject has explicitly refused, revoked or withdrawn its consent.  In these cases, data can be stored and processed for compliance with law only. The lifetime of User’s personal information depends on the type of information collected by the Data Controller. Aflofarm shall retain personal information of the User only as long as it is required or necessary to comply with the obligations and provisions set out in this Privacy Notice and Cookie Policy. After this time, Aflofarm will delete or anonymise personal data of the User. In case processing personal data is based on data subject’s consent, the information is stored for further processing for as long as  any such consent is withdrawn or  revoked by the data subject. </li>
              <li>Data Controller shall take reasonable technical and operational steps to protect and safeguard your personal information from unintentional or intentional manipulation, interference, misuse, disclosure, loss, erasure or destruction or unauthorised access. </li>
              <li>Upon data collection, unless waived under separate law and regulations,  Data Controller shall ensure transparency of the processing of personal data by informing of the legal basis and the purpose and term for which any such data is collected, so that you are always aware of what information we collect, the purpose for which we collect it,  its lifetime and under what circumstances we disclose it.</li>
              <li>Data Controller shall safeguard and ensure confidentiality of the personal information processed by the Data Controller and access for data subjects to their personal information. In case of security incidents resulting in breach of personal data despite the applied safeguards and other security measures (breach of confidentiality, loss of accessibility or integrity) and any such breach might involve high likelihood of violating the rights or freedoms of data breach, you will be notified by the Data Controller accordingly pursuant to the applicable regulations</li>
              <li>Data Controller will take every reasonable step to make sure its authorised employees, contractors and other third party agents or service providers are aware of Data Controller’s privacy commitment and our obligations in the collection, use and disclosure of personal information and submit to the obligation of compliance with the appropriate privacy laws when processing your personal data. </li>
              <li>Data Controller performs an ongoing monitoring of risk and safeguards applied. If necessary, additional measures aimed to protect privacy of you data are deployed. </li>
              <li>Recipients of your personal data: 
                  <p>Please note that the transfer of personal information by the Data Controller shall not pertain to all circumstances or recipients indicated in this Privacy Policy Notice. The processing of any personal information by the Data Controller at all times shall be limited only to these recipients and circumstances with respect to which any such processing is required under a  purpose pursued and only to the reasonable and justifiable extent. </p>
                  <p>Personal information will be disclosed to </p>
                  <ol type="a">
                      <li>The recipients of your personal data will only be (1) authorised employees and staff or other persons acting for and on behalf of Aflofarm, (2) third parties collaborating with or partnering to Aflofarm, i.e. third party telecommunications system and service providers (telecommunication infrastructure and facilities, electronic mail, IT systems used by Aflofarm), (3) supervisory authorities, governmental bodies and other public offices or third party agencies for compliance with applicable laws and regulations (4) statutory auditors and other counselling professional bodies. </li>
                      <li>Personal data can be shared  and disclosed by the Data Controller to its Partners based on vested or legitimate interest of the Data Controller or based on your explicit consent.  The processing of your data saved in cookie files on your devices and in its cache (including data shared in your browsing history or collected through your Website performance) and location data generated by your device  - for marketing purposes (including automated analytics (like cookie) recording and reporting your browsing activity on your device).</li>
                      <li>Furthermore, personal data can be transferred to social media plugins, scripts, and other similar media tools enabling a web browser of a visitor to access and save content of third-party provider plugins and thereby share personal data of a visitor, with plugins for social sharing used by the Data Controller including Facebook which collects and discloses User’s personal information  visiting Facebook Ireland service under the privacy policy as set out at the website address <a data-fr-linked="true" href="https://www.facebook.com/about/privacy">https://www.facebook.com/about/privacy</a> for Facebook Ireland Ltd. and <a data-fr-linked="true" href="https://pl.linkedin.com/legal/privacy-policy, ">https://pl.linkedin.com/legal/privacy-policy</a> for LinkedIn Ireland Unlimited Company,</li>
                  </ol>
                  <p>Recipient: Meta Platforms Ireland Limited, Block J, Serpentine Avenue, Dublin 4, Ireland </p>
                  <p>TikTok in accordance with privacy policy provisions at the website address : <a data-fr-linked="true" href="https://www.tiktok.com/legal/page/eea/privacy-policy/pl. ">https://www.tiktok.com/legal/page/eea/privacy-policy/pl.</a>; </p>
                  <p>Contact: TikTok Technology Limited, 10 Earlsfort Terrace, Dublin, D02 T380, Ireland. If you wish to access your data or exercise your rights, contact the third party service provider directly. It means only only some service providers have access to your personal information, can share or handle such information, if needed. Should you need assistance or support in exercising your rights, you can contact us at any time. Information on how your personal data is processed by each service provider and requirements for complaint submission (revocation / withdrawal) is available at respective policy notices on the websites of respective service providers, see above.</p>
              </li>
              <li>If the processing of User’s personal data is based on the User’s consent, you may, at any time, revoke your consent to the processing of your personal data, however, legality of the data processing carried out remains affected until any such revocation. The consent can be withdrawn or revoked by a notification sent to: <a data-fr-linked="true" href="mailto:daneosobowe@aflofarm.pl">daneosobowe@aflofarm.pl</a>.</li>
              <li>By visiting and using our Website you receive marketing and advertising material which is an integral part thereof. The integral part shall also refer to and include online banner solutions where Aflofarm or the Controller’s partnering third party partners can share  their ads and provide you with updated information about product offering or other marketing content. </li>
              <li>At any time you have the right to access your personal data, including the right to delete it or to restrict or limit the processing or the rights to data transfer. If you wish so, please contact us at <a data-fr-linked="true" href="mailto:daneosobowe@aflofarm.pl">daneosobowe@aflofarm.pl</a>.If you think your data protection rights have been violated, you can lodge a complaint with the Polish Data Protection Authority Officer. If you are dissatisfied with the way in which we hold, handle and process your personal data you may revoke your consent to the processing of your personal data under your legitimate interest. </li>
              <li>Any matters not covered by this Privacy Policy shall be construed and governed by the applicable law.</li>
              <li>From time to time, our policies will be reviewed and may be revised. We reserve the right to change our Privacy Policy at any time and notify you by posting an updated version of the policy on our Website. </li>
          </ol>
            <br />
          <h2 id={"cookies"}>COOKIE POLICY</h2>
          <ol>
              <li>What are cookies? 
                  <p>Cookies are small text files that are placed on your computer, a smartphone or other mobile device by websites that you visit or a browser. They are widely used to improve and facilitate browsing experience and enable users to move around websites and use their features.</p>
              </li>
              <li>What types and categories of cookies do we use? 
                  <ul type={'disc'}>
                      <li>First-party cookies – these cookies are installed and collected by the Website on and from the end terminal or device of the User; </li>
                      <li>Third-party cookies - these cookies are installed and collected by second or third party websites on and from the end terminals or devices of users. </li>
                      <li>Session cookies - these cookies are temporary and enable websites to recognize their users and the information provided when they navigate through a website. Session cookies only retain information about a user’s activity from user’s device for as long as they are on the website. Once the web browser is closed, the cookies are deleted from user’s device. </li>
                      <li>Permanent (persistent) cookies - these cookies remain in operation even after the web browser has closed. Cookies are not deleted after the session is over unless the cookie settings are configured for cookies to be deleted after the end of the session. These cookies are saved on the device of the visitor to the site and are used to remember website settings to enable the website owner to provide personalised user browsing experience. The lifetime of cookies is defined by your settings for the website or remain on the device until deleted by the User by clearing browsing history of his/her browser. </li>
                      <li>Strictly necessary cookies - these cookies enable you to move around our website and use its basic features, such as accessing secure areas of the website. These cookies save information required for the website to function properly and to enable core functionality of our Website. These cookies do not require User’s consent under privacy laws nor do they collect personal information from your mobile device. The lifetime of cookies is defined by your opt-for or opt-out settings for the browser. You can block or restrict access to cookies under the browser cookie settings however this can impair functionality of the Website. Check the list of strictly necessary cookies. </li>
                      <li>Analytics cookies - these cookies track how you use, navigate and interact with our Website. These cookies do not collect personally identifiable information (PII), i.e. information that enables identification of a user. The cookies enable us to collect information about your device, web browser you use, your approximate location and how you use our Website service (source traffic and websites from which you visit our Website, your browsing activity in terms of which websites you visit and for how long, is this your first visit to our Website, etc.) also demographics data such as age or gender. Thanks to it, we can prepare reports and statistical metrics which can help improve your use experience on our Website. We use Google Analytics, and some information collected are transferred to a Google server; for more; </li>
                      <li>Advertising cookies - we wish to display advertising that is targeted, personalised and customised to your preferences and interests and collect information on its effectiveness. For this reason, we and our partners in advertising use various cookies. They are used to track traffic on the Website. Some of these cookies enable tracking of your activity or behaviour on our Website (like products or items viewed), collect information to customise or personalise third party advertising. In this way you can see our advertising banners on other websites that are customised or personalised to your preferences (i.e. if you browse for our products the advertising of these products can pop up in other websites domains. The lifetime of these cookies is up to 14 months. </li>
                  </ul>
              </li>
              <li>Security 
                  <p>The mechanism of cookies does not allow for downloading any personal data or any confidential information from the User’s device. It is virtually impossible to transfer viruses, Trojan, spyware or malware to the User’s Terminal Device. </p>
                  <p>First-party cookies - these first-party cookies used by the Data Controller are safe for the User’s terminal devices. </p>
              </li>
              <li>Purposes for which Cookies are used 
                  <p>By using our Site, you consent to our privacy policy. By default, browsing software enables placing cookies on your terminal device. This ensures access to all functions or parts on our Website. At any time a visitor to our Website may change browser settings if you do not wish to receive any cookies. If you wish to restrict or block the cookies which are set automatically by our Website, or indeed any other website, or to consent each time under privacy and security settings for cookies to be placed on your device, you can do this through your browser or Website settings or banner available at our Site. </p>
                  <p>Access to the Website - the Data Controller may store information about the User's preferences and settings regarding the Website in Cookies in order to improve site performance and facilitate user experience on the Website. </p>
                  <p>PROFILING: Your personal data will be profiled and matched to your preferences or interests depending your activity on our Website, content or items viewed based on collected statistics on your website behaviour aimed to improve Site performance. Data Controller may use profiling. </p>
                  <p>Statistics and analytics – Data Controller and third party service providers use cookies to collect and process the collected information for the statistics and analytics analysis. They include Site traffic metrics, information on end User’s terminal devices and Users’ Website behaviour, and also demographics data which pertain to age and gender.</p>
                  <p>Advertising cookies  - these cookies on our Website enable analysing user preferences based on how frequently you visit our Site, items and content viewed and articles read. The analysis of website behaviour enable the Data Controller to understand preferences and expectations of individual Users and  provide more targeted content that will better match their individual interests and needs. In this way, the Data Controller can serve profiled advertising (like an ad customised based on the history of recently viewed articles on heart attacks) or other content, including articles, which will most closely reflect User’s interests or needs.  </p>
                  <p>Blocking or disabling cookies which allow for profiled advertising does not mean you will not see any advertising while browsing our Website. Instead, you will receive marketing content that is not related to the content viewed and irrespective of it. </p>
                  <p>Serving multimedia services – the Website and external websites use Cookies to serve multimedia services to Users</p>
              </li>
              <li>Links to external websites 
                  <p>Our Website has links to external third party websites. More detailed information is detailed below: </p>
                  <p>Analytics data provider  - In order to get a better understanding of how our Website works, we collaborate with external providers of data analytics as indicated in section 8. </p>
                  <p>For more information on how you data is used by third party partners, please see their website. </p>
                  <p>For the full list of partnering analytics data providers, see section 8. </p>
                  <p>Advertising partners – Data Controller’s advertising partners can use advertising cookies in order to display marketing content which, at their discretion, will best match User’s interest or that will best enable to analyse its efficiency.  Our partners use their own cookies and use these cookies as outlined in their cookie policies (e.g. to target or provide any such targeted marketing content on websites served by these partners). Advertising cookies do not collect information which can identify you as an individual. They include</p>
                  <ul type={'disc'}>
                      <li>Your location or whether you clicked on our advertising and whether it lead to finalised purchase, etc. For full list of advertising partners, see section 8.</li>
                  </ul>
              </li>
              <li>Terms and Conditions for Website’s access to User’s terminal device 
                  <p>The User can at any time change settings under privacy and security settings for Cookies, deleting and access to cookies set by our website including revocation of your consent (providing it is not to the contrary of the lawfulness of activity throughout the validity of consent).</p>
                  <p>Information on disabling Cookies in most popular browsers and mobile devices are available at:</p>
                  <p>Firefox: <a data-fr-linked="true" href="https://support.mozilla.org/pl/kb/blokowanie-ciasteczek ">https://support.mozilla.org/pl/kb/blokowanie-ciasteczek</a>;</p>
                  <p>Chrome: <a data-fr-linked="true" href="https://support.google.com/chrome/answer/95647?hl=pl&co=GENIE.Platform%3DDesktop#zippy= ">https://support.google.com/chrome/answer/95647?hl=pl&amp;co=GENIE.Platform%3DDesktop#zippy=</a>;</p>
                  <p>Internet Explorer: <a data-fr-linked="true" href="https://support.microsoft.com/pl-pl/windows/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d ">https://support.microsoft.com/pl-pl/windows/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d</a>;</p>
                  <p>Opera: <a data-fr-linked="true" href="https://help.opera.com/pl/touch/settings/ ">https://help.opera.com/pl/touch/settings/</a>;</p>
                  <p>Safari: <a data-fr-linked="true" href="https://support.apple.com/pl-pl/guide/safari/ibrw850f6c51/mac ">https://support.apple.com/pl-pl/guide/safari/ibrw850f6c51/mac</a>;</p>
                  <p>Given the multitude of technological solutions, it is not possible to provide precise guidelines on how specific terms and condition for storing or accessing cookies using the settings of all available websites end devices and software installed in this device. However, in most cases, it can be done by selecting "Tools" or "Settings" to configure cookie settings or manage privacy while browsing the Internet.</p>
                  <p>Detailed information is usually provided by the manufacturer of a given device or browser in the instructions for use or on the relevant website.</p>
                  <p>The User may at any time delete all Cookies placed on User’s device while vising our Website using the tools available on the User's Device used to browse our Website.</p>
              </li>
              <li>Right to restricting or blocking Cookies  
                  <p>Due to restricting or blocking storage and access to Cookies on User’s Device, you may not be able to use all functions and features of our Website. </p>
                  <p>We are not responsible for limited functionality of our Website due to restricting or blocking storage and access to Cookies by the User or visitor to the Site. </p>
              </li>
              <li>Third Party Access to Cookies 
                  <p>If you have given your consent, our Website uses Google Analytics. Google Analytics is a web analytics service provided by Google LLC, USA. Google Analytics uses cookies that enable an analysis of your use and behaviour on our Website. Google Analytics does not store or process personal information that could be used to identify a person. The information collected is limited to information on Your device, browser, location and how you use our Website (sites visited, frequency, duration and recurrence of your visits to our Website) and demographics data like age or gender. For highest security standards, we enabled IP anonymization feature in Google Analytics.</p>
                  <ol>
                      <li>
                          Provider: Google Ireland Limited, Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland 
                          <p>Privacy Policy:<a data-fr-linked="true" href="https://policies.google.com/privacy?hl=de ">https://policies.google.com/privacy?hl=de</a>;</p>
                          <p>Terms and Conditions: <a data-fr-linked="true" href="https://marketingplatform.google">https://marketingplatform.google</a> com/about/analytics/terms/us/</p>
                          <p>Consent Withdrawal:<a data-fr-linked="true" href="http://tools.google.com/dlpage/gaoptout?hl=de ">http://tools.google.com/dlpage/gaoptout?hl=de</a>;</p>
                          <p>Privacy settings:<a data-fr-linked="true" href="https://myaccount.google.com/privacycheckup?utm_source=pp&utm_medium=Promo-in-product&utm_campaign=pp_intro&pli=1 ">https://myaccount.google.com/privacycheckup?utm_source=pp&amp;utm_medium=Promo-in-product&amp;utm_campaign=pp_intro&amp;pli=1</a>;</p>
                          <p>More about Google Analytics: If you have given your consent, our Website uses Google Analytics 4, a web analytics service provided by Google LLC. The responsible party for users in the EU/EEA and Switzerland is Google Ireland Limited, Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland (“Google”). </p>
                          <p>Scope of processing: Google Analytics 4 uses cookies that enable an analysis of Your use of our websites. Google Analytics 4 has IP address anonymization enabled by default. Due to IP anonymization, your IP address will be shortened by Google within member states of the European Union or in other states party to the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be transferred to a Google server in the USA and shortened there. According to Google, the IP address transmitted by your browser as part of Google Analytics will not be merged with other Google data. </p>
                          <p>Recipients of the data are/may be, in particular: </p>
                          <ul type={"disc"}>
                              <li>Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland (as processing party under Article 28 GDPR).</li>
                              <li>Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA</li>
                              <li>Alphabet Inc, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA </li>
                          </ul>
                          <p>It cannot be ruled out that US authorities may access the data stored by Google.</p>
                          <p>The lifetime of cookies is 14 months. </p>
                          <p>Through Google Analytics  the Data Controller uses Consent Mode v2. Consent Mode v2 is a framework designed to integrate website visitor consent preferences with Google's advertising and analytics tools which enables to collect pings of analytical and marketing data depending on the consent given or declined by Users. With it, websites can adjust how these tools behave based on the consent status of website visitors, particularly regarding cookies and data collection. This tool enables to collect such analytical cookies only if a User has consented to do so.  Depending on whether you gave consented to or not, any such cookies will or will not be collected. </p>
                      </li>
                      <li>Google Tag Manager 
                          <p>We use Google Tag Manager provided by Google LLC, USA. Google Tag Manager may collect some aggregated data about tag firing in order to monitor and provide diagnostics about system stability, performance and installation quality.  This data does not include user IP addresses or any measurement identifiers associated with a particular individual. Google Tag Manager does not collect, store or share any information of visitors to the sites, including URL addresses and diagnostics information.  The exception is data stored in standard HTTP request posts which are deleted in full within 14 days upon receipt. </p>
                          <p>For further details on How Google Tag Manager uses the collected data, see Terms and Conditions:</p>
                          <p><a data-fr-linked="true" href="https://support.google.com/tagmanager/answer/7157428?sjid=11585760675700610593-EU ">https://support.google.com/tagmanager/answer/7157428?sjid=11585760675700610593-EU</a>;</p>
                          <p>Google Privacy Policy:<a data-fr-linked="true" href="https://policies.google.com/privacy?hl=pl ">https://policies.google.com/privacy?hl=pl</a>;</p>
                          <p>Terms of Use of Google Tag Manager:</p>
                          <p>&nbsp;<a data-fr-linked="true" href="https://marketingplatform.google.com/about/analytics/tag-manager/use-policy/ ">https://marketingplatform.google.com/about/analytics/tag-manager/use-policy/</a>;</p>
                      </li>
                      <li>Our  Website keeps social media add-ins converting to our social profile sites and platforms, as aforementioned, and analytics and marketing tools, like: 
                        <ol type={'a'}>
                            <li>META (Facebook) Pixel
                                <p>META (Facebook) Pixel is an analytical add-in,  a snippet of code that allows us to track, monitor visitor or browser activity on our website or who showed interest in or took action on our site. </p>
                                <p>Whereas data collected by this tool are anonymised (location, gender, age, user’s online activity and behaviour) the provider may combine this data with data collected in connection with your activity on its website. META (Facebook) Pixel helps us determine and measure the effectiveness of our ads, retarget or define custom audience  and enables to track how they respond to our content. </p>
                                <p>For more details see:</p>
                                <p><a data-fr-linked="true" href="https://pl-pl.facebook.com/business/help/742478679120153?id=1205376682832142. ">https://pl-pl.facebook.com/business/help/742478679120153?id=1205376682832142.</a>;</p>
                            </li>
                            <li>TikTok Pixel 
                                <p>The Tik Tok Pixel is a piece of code that we place on our Website that allows us to share TikTok events on the website via browser. This tool enables us to track site traffic and analyse advertising campaign efficiency to optimise marketing content.</p>
                                <p>For more information on this tool see: </p>
                                <p><a data-fr-linked="true" href="https://ads.tiktok.com/help/article/tiktok-pixel?lang=pl-PL ">https://ads.tiktok.com/help/article/tiktok-pixel?lang=pl-PL</a>;</p>
                                <p><a data-fr-linked="true" href="https://ads.tiktok.com/help/article/using-cookies-with-tiktok-pixel?redirected=2 ">https://ads.tiktok.com/help/article/using-cookies-with-tiktok-pixel?redirected=2</a>;</p>
                            </li>
                        </ol>
                      </li>
                      <li>Third party advertising partners 
                          <p>Our advertising partners may use advertising cookies to display marketing content or adds which, at their own discretion, best targets user’s interests and in order to enable marketing analytics in the Service.  Our third party partners in advertising use their cookies under their own privacy policies (inter alia to receive more personalised, targeted or customised advertising that is displayed on websites operated by these partners). We advise you to read the list of partners in advertising. Advertising cookies do not store any personally identifiable information about users. They are limited inter alia to information like location, or whether you clicked on our advertising and whether it lead to finalised purchase.</p>
                      </li>
                  </ol>
              </li>
              <li>Changes to the Privacy and Cookie Policy 
              <ol>
                  <li>From time to time, our policies will be reviewed and may be revised. We reserve the right to change our Privacy & Cookie Policy at any time.</li>
                  <li>Any revisions to our Privacy & Cookie Policy will be notified to you by posting an updated version of the policy on our website.</li>
                  <li>Changes to this Policy shall become effective upon publication.</li>
              </ol>
              </li>
          </ol>
          <script id="CookieDeclaration" src="https://consent.cookiebot.com/946c2d7b-6f97-449c-bd6e-5e955623fe7b/cd.js" type="text/javascript"></script>
        </PolicyWrapper>
      </ContentPageWrapper>
    </ContentPage> 
  </Layout>
)

export default PrivacyPolicy
